import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import {aliases, fa} from 'vuetify/lib/iconsets/fa';
import {mdi} from 'vuetify/lib/iconsets/mdi';
import '@fortawesome/fontawesome-free/css/all.css';

const oninoTheme = {
    dark: true,
    colors: {
        surface: '#242939',
        primary: '#3699FF',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
};

const lightTheme = {
    dark: false,
    colors: {
        surface: '#ffffff',
        primary: '#eb4034',
        'primary-darken-1': '#eb4034',
        secondary: '#eb4034',
        'secondary-darken-1': '#eb4034',
        error: '#eb4034',
        info: '#eb4034',
        success: '#eb4034',
        warning: '#eb4034',
    },
};

export default createVuetify({
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
            mdi,
        }
    },
    theme: {
        defaultTheme: 'oninoTheme',
        themes: {
            oninoTheme,
            lightTheme
        },
    },
    components,
    directives,
});
