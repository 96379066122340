import {defineStore} from 'pinia';
import {ref} from 'vue';

export default defineStore('drawer', () => {
    const open = ref<boolean>(false);

    const toggle = () => {
        open.value = !open.value;
    };

    return { open, toggle};
});
