import HttpClient from '../../domain/HttpClient';
import CnpjData from '../../domain/classes/CnpjData';

export default class CnpjSearchService {
    uri = '/api/get-cnpj-data/{cnpj}';

    constructor(readonly client: HttpClient) {}

    async get(cnpj: string) {
        const data = await this.client.get(this.getUri(cnpj));
        const phones = new String(data.telefone);
        const phone = phones.split('/')[0].trim();

        return new CnpjData(
            data.nome,
            data.email,
            phone,
            data.cep,
            data.logradouro,
            data.numero,
            data.bairro,
            data.municipio,
            data.uf,
            data.complemento,
        );
    }

    getUri(cnpj: string): string {
        return this.uri.replace('{cnpj}', cnpj);
    }
}
