import { RouteRecordRaw } from 'vue-router';
import ProjectList from '@/app/project/ui/views/ProjectList.vue';
import ProjectForm from '@/app/project/ui/views/ProjectForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/projetos',
        name: 'see_projects_list',
        component: ProjectList,
    },
    {
        path: '/projetos/cadastrar',
        name: 'create_project',
        component: ProjectForm,
    },
    {
        path: '/projetos/editar/:id',
        name: 'see_project',
        component: ProjectForm,
        props: true,
    },
];

export default routes;
