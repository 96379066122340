import { defineStore } from 'pinia';
import { inject, ref } from 'vue';
import RefundRepository from './RefundRepository';

export default defineStore('refunds', () => {
    const refundCategories = ref<any[]>([]);
    const repository = inject('refundRepository') as RefundRepository;

    async function sync() {
        refundCategories.value = await repository.getCategories();
    }

    async function getAllSimple() {
        if (refundCategories.value.length === 0) {
            await sync();
        }

        return refundCategories.value;
    }

    return {
        sync,
        getAllSimple,
    };
});
