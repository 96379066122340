import { RouteRecordRaw } from 'vue-router';
import BillReceivableList from '@/app/bill-receivable/ui/views/BillReceivableList.vue';
import BillReceivableForm from '@/app/bill-receivable/ui/views/BillReceivableForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/contas-a-receber',
        name: 'see_bills_receivable_list',
        component: BillReceivableList,
    },
    {
        path: '/contas-a-receber/cadastrar',
        name: 'create_bill_receivable',
        component: BillReceivableForm,
    },
    {
        path: '/contas-a-receber/editar/:id',
        name: 'see_bill_receivable',
        component: BillReceivableForm,
        props: true,
    },
];

export default routes;
