import router from '@/router';
import { computed, watch } from 'vue';
import notificationRepository from '@/app/shared/infrastructure/persistence/notification-repository';
import Repository from '../../infrastructure/http/Repository';
import useOverlayState from '@/app/shared/infrastructure/state/overlay';

export function useForm(
    props: any,
    resource: any,
    repository: Repository,
    form: any,
    pageEditTitle: string,
    pageCreateTitle: string,
    urlNotFound: string,
    successRedirect: string,
) {
    const isEditPage = computed(() => props.id !== undefined);
    const overlayState = useOverlayState();

    watch(props, () => {
        if (!props.id) {
            return;
        }

        fillResource();
    }, { immediate: true });

    async function fillResource() {
        try {
            const response = await repository.getById(props.id);
            const keys = Object.keys(response);
            
            keys.forEach(key => {
                (resource as any)[key] = response[key];
            });
        } catch (error) {
            setTimeout(() => router.push(urlNotFound), 2000);
        }
    }

    const sendRequest = async (data: any) => {
        const formValidator: any = form.value;
        const {valid} = await formValidator.validate();

        if (valid) {
            overlayState.set(true);

            try {
                if (props.id) {
                    await repository.updateAll(props.id, data);
                    notificationRepository().insert({
                        id: Date.now().toString(),
                        type: 'success',
                        message: 'Atualizado com sucesso.',
                    });
                } else {
                    await repository.insert(data);
                    notificationRepository().insert({
                        id: Date.now().toString(),
                        type: 'success',
                        message: 'Criado com sucesso.',
                    });
                    router.push(successRedirect);
                }
            } finally {
                overlayState.set(false);
            }
        } else {
            notificationRepository().insert({
                id: Date.now().toString(),
                type: 'warning',
                message: 'Existem campos inválidos.',
            });
        }
    };

    const submit = async () => {
        await sendRequest(resource);
    };

    const submitAttach = async (attachment: any) => {
        const formData = new FormData();
        if (attachment) {
            formData.append('attachment', attachment);
        }
        formData.append('data', JSON.stringify(resource));

        await sendRequest(formData);
    };

    const pageTitle = computed(() => isEditPage.value ? pageEditTitle : pageCreateTitle);

    return {
        pageTitle,
        submit,
        isEditPage,
        submitAttach,
    };
}
