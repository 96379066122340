import { API_URL_BASE } from '@/env';
import axios, { AxiosInstance } from 'axios';
import ClientFactory from './ClientFactory';

export default class AuthClientFactory implements ClientFactory {
    factory(): AxiosInstance {
        return axios.create({
            baseURL: API_URL_BASE,
            timeout: 20000,
        });
    }
}
