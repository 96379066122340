import HttpClient from '@/app/shared/domain/HttpClient';

export default class CompanyRepository {
    uri = '/api/company';

    constructor (readonly client: HttpClient) {
    }

    async get() {
        return await this.client.get(this.uri);
    }

    async update(data: any) {
        return await this.client.post(`${this.uri}/update`, data);
    }

    async getAccounts() {
        return await this.client.get(`${this.uri}/accounts/simple`);
    }
}
