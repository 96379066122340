import { defineStore } from 'pinia';
import { inject, ref } from 'vue';
import CompanyRepository from './CompanyRepository';

export default defineStore('company', () => {
    const companyAccounts = ref<any[]>([]);
    const repository = inject('companyRepository') as CompanyRepository;

    async function syncAccounts() {
        const response = await repository.getAccounts();
        companyAccounts.value = response;
    }

    async function getAllSimpleAccounts() {
        if (companyAccounts.value.length === 0) {
            await syncAccounts();
        }

        return companyAccounts.value;
    }

    return {
        syncAccounts,
        getAllSimpleAccounts,
    };
});
