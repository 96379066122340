import BrowserRepository from '../../domain/BrowserRepository';
import DependencyInjection from '../provider/DependencyInjection';

const TOKEN_KEY = 'onino-token';

type TokenManager = {
    set: (value: string) => void,
    get: () => string | null,
    remove: () => void,
}

export default () : TokenManager => {

    const browserRepository = DependencyInjection.inject('browserRepository') as BrowserRepository;

    const set = (value: string): void => {
        browserRepository.insert(TOKEN_KEY, value);
    };

    const get = (): string | null  => {
        return browserRepository.get(TOKEN_KEY);
    };

    const remove = (): void => {
        browserRepository.remove(TOKEN_KEY);
    };

    return {
        set,
        get,
        remove,
    };
};
