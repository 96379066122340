import { defineStore } from 'pinia';
import { inject, ref } from 'vue';
import ProviderRepository from './ProviderRepository';

export default defineStore('providers', () => {
    const providers = ref<any[]>([]);
    const repository = inject('providerRepository') as ProviderRepository;

    async function sync() {
        const response = await repository.getAllSimple();
        providers.value = response;
    }

    async function getAllSimple() {
        if (providers.value.length === 0) {
            await sync();
        }

        return providers.value;
    }

    return {
        sync,
        getAllSimple,
    };
});
