import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from '@/plugins/vuetify';
import pinia from '@/plugins/pinia';
import VueTheMask from 'vue-the-mask';
import DependencyInjection from './app/shared/infrastructure/provider/DependencyInjection';
import AxiosClient from './app/shared/infrastructure/http/AxiosClient';
import CostCenterRepository from './app/cost-center/infrastructure/persistence/CostCenterRepository';
import BillReceivableRepository from './app/bill-receivable/infrastructure/persistence/BillReceivableRepository';
import BillToPayRepository from './app/bill-to-pay/infrastructure/persistence/BillToPayRepository';
import BillingRepository from './app/billing/infrastructure/persistence/BillingRepository';
import ClientRepository from './app/client/infrastructure/persistence/ClientRepository';
import CollaboratorRepository from './app/collaborator/infrastructure/persistence/CollaboratorRepository';
import CompanyRepository from './app/my-company/infrastructure/persistence/CompanyRepository';
import ProjectRepository from './app/project/infrastructure/persistence/ProjectRepository';
import ProviderRepository from './app/provider/infrastructure/persistence/ProviderRepository';
import RefundRepository from './app/refund/infrastructure/persistence/RefundRepository';
import ServiceOrderRepository from './app/service-order/infrastructure/persistence/ServiceOrderRepository';
import ApiClientFactory from './app/shared/infrastructure/http/ApiClientFactory';
import CnpjSearchService from './app/shared/application/services/CnpjSearchService';

const apiClientFactory = new ApiClientFactory();
const httpClient = new AxiosClient(apiClientFactory);
const costCenterRepository = new CostCenterRepository(httpClient);
const billReceivableRepository = new BillReceivableRepository(httpClient);
const billToPayRepository = new BillToPayRepository(httpClient);
const billingRepository = new BillingRepository(httpClient);
const clientRepository = new ClientRepository(httpClient);
const collaboratorRepository = new CollaboratorRepository(httpClient);
const companyRepository = new CompanyRepository(httpClient);
const projectRepository = new ProjectRepository(httpClient);
const providerRepository = new ProviderRepository(httpClient);
const refundRepository = new RefundRepository(httpClient);
const serviceOrderRepository = new ServiceOrderRepository(httpClient);
const cnpjSearchService = new CnpjSearchService(httpClient);

const app = createApp(App)
    .use(router)
    .use(vuetify)
    .use(pinia)
    .use(VueTheMask);

app.provide('costCenterRepository', costCenterRepository);
app.provide('billReceivableRepository', billReceivableRepository);
app.provide('billToPayRepository', billToPayRepository);
app.provide('billingRepository', billingRepository);
app.provide('clientRepository', clientRepository);
app.provide('collaboratorRepository', collaboratorRepository);
app.provide('companyRepository', companyRepository);
app.provide('projectRepository', projectRepository);
app.provide('providerRepository', providerRepository);
app.provide('refundRepository', refundRepository);
app.provide('serviceOrderRepository', serviceOrderRepository);
app.provide('cnpjSearchService', cnpjSearchService);

DependencyInjection.register(app);

app.mount('#app');
