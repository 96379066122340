import { RouteRecordRaw } from 'vue-router';
import Home from '@/app/shared/ui/views/Home.vue';
import Welcome from '@/app/shared/ui/views/Welcome.vue';
import LoginView from '../views/LoginView.vue';
import RetrievePasswordView from '../views/RetrievePasswordView.vue';
import CreatePasswordView from '../views/CreatePasswordView.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'welcome',
        component: Welcome,
    },
    {
        path: '/dashboard',
        name: 'see_dashboard',
        component: Home,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/redefinir-senha',
        name: 'redefinir-senha',
        component: RetrievePasswordView,
    },
    {
        path: '/criar-senha',
        name: 'criar-senha',
        component: CreatePasswordView,
    },
];

export default routes;
