import HttpClient from '@/app/shared/domain/HttpClient';

export default abstract class Repository {
    abstract uri: string;
    
    constructor (readonly client: HttpClient) {
    }

    async getAll() {
        return await this.client.get(this.uri);
    }

    async getAllSimple(filters: any = null) {

        if (!filters) {
            return await this.client.get(`${this.uri}/simple`);
        }

        const params = new URLSearchParams(filters);

        return await this.client.get(`${this.uri}/simple?${params.toString()}`);
    }

    async remove(id: string) {
        return await this.client.delete(`${this.uri}/${id}`);
    }

    async getById(id: string) {
        return await this.client.get(`${this.uri}/${id}`);
    }

    async insert(data: any) {
        return await this.client.post(this.uri, data);
    }

    async updateAll(id: string, data: any) {
        return await this.client.post(`${this.uri}/${id}`, data);
    }
}
