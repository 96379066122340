import { RouteRecordRaw } from 'vue-router';
import BillToPayList from '@/app/bill-to-pay/ui/views/BillToPayList.vue';
import BillToPayForm from '@/app/bill-to-pay/ui/views/BillToPayForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/contas-a-pagar',
        name: 'see_bills_to_pay_list',
        component: BillToPayList,
    },
    {
        path: '/contas-a-pagar/cadastrar',
        name: 'create_bill_to_pay',
        component: BillToPayForm,
    },
    {
        path: '/contas-a-pagar/editar/:id',
        name: 'see_bill_to_pay',
        component: BillToPayForm,
        props: true,
    },
];

export default routes;
