import { RouteRecordRaw } from 'vue-router';
import RefundList from '@/app/refund/ui/views/RefundList.vue';
import RefundForm from '@/app/refund/ui/views/RefundForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/reembolsos',
        name: 'see_refunds_list',
        component: RefundList,
    },
    {
        path: '/reembolsos/cadastrar',
        name: 'create_refund',
        component: RefundForm,
    },
    {
        path: '/reembolsos/editar/:id',
        name: 'see_refund',
        component: RefundForm,
        props: true,
    },
];

export default routes;
