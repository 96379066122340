export default {
    required: (value: string) => {
        if (
            !value
            || value === '0,00'
        ) {
            return 'Campo obrigatório.';
        }
        return true;
    },

    requiredCombobox: (value: string[]) => {
        if (
            value.length === 0
        ) {
            return 'Campo obrigatório.';
        }
        return true;
    },

    cpf: (value: string) => value ? /[0-9]{3}[.][0-9]{3}[.][0-9]{3}[-][0-9]{2}/.test(value) || 'Insira um cpf válido.' : true,

    cnpj: (value: string) => value ? /[0-9]{2}[.][0-9]{3}[.][0-9]{3}[/][0-9]{4}[-][0-9]{2}/.test(value) || 'Insira um cnpj válido.': true,

    email: (value: string) => value ? /\S+@\S+\.\S+/.test(value) || 'Insira um e-mail válido.' : true,

    phone: (value: string) => value ? /[(][0-9]{2}[)][0-9]?[0-9]{4}[-][0-9]{4}/.test(value) || 'Insira um telefone válido.' : true,

    cnae: (value: string) => value ? /[0-9]{4}[-][0-9][/][0-9]{2}/.test(value) || 'Insira um cnae válido.' : true,

    cep: (value: string) => value ? /[0-9]{2}[.][0-9]{3}[-][0-9]{3}/.test(value) || 'Insira um cep válido.' : true,

    date: (value: string) => {
        if (!value) {
            return true;
        }
        
        if (value?.length !== 10) {
            return 'Insira uma data válida.';
        }

        const year = Number(value.substring(6));
        const month = Number(value.substring(3,5));
        const day = Number(value.substring(0,2));

        if (
            month > 12
            || day > 31
            || year == 0
        ) {
            return 'Insira uma data válida.';
        }

        return true;
    },

    futureDate: (value: string) => {
        if (!value) {
            return true;
        }

        const year = Number(value.substring(6));
        const month = Number(value.substring(3,5));
        const day = Number(value.substring(0,2));

        const date = new Date(year, month-1, day);
        const now = new Date();

        if ((now.getTime() - date.getTime()) < 0) {
            return 'Não é permitido uma data futura.';
        }

        return true;
    },
    hour: (value: string) => {
        if (!value) {
            return true;
        }

        if (value.length !== 5) {
            return 'Insira um horário válido';
        }

        const data = value.split(':');

        const hour = Number(data[0]);
        const minute = Number(data[1]);

        if (
            hour >= 24
            || minute >= 60
        ) {
            return 'Insira um horário válido';
        }

        return true;
    } 
};
