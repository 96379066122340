import BrowserRepository from '@/app/shared/domain/BrowserRepository';

export default class SessionRepository implements BrowserRepository {
    
    insert(key: string, data: any): void {
        
        sessionStorage.setItem(key, JSON.stringify(data));
    }

    get(key: string): any {
        const data = sessionStorage.getItem(key);
        return data ? JSON.parse(data) : null;
    }

    remove(key: string): void {
        sessionStorage.removeItem(key);
    }   
}
