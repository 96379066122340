import { defineStore } from 'pinia';
import { ref } from 'vue';
import Notification from '@/app/shared/domain/interfaces/Notification';

export default defineStore('notifications', () => {
    const notifications = ref<Notification[]>([]);

    const insert = (notification: Notification) => {
        notifications.value.push(notification);
        setTimeout(() => {
            notifications.value = notifications.value.filter(item => item.id !== notification.id);
        }, 3000);
    };

    return {
        insert,
        notifications,
    };
});
