export default class StatusMapper {
    static map(value: string): string {
        switch(value) {
        case 'pending':
            return 'Pendente';
        case 'approved':
            return 'Aprovado';
        case 'disapproved':
            return 'Reprovado';
        case 'payed':
            return 'Pago';
        case 'received': 
            return 'Recebida';
        case 'open':
            return 'Aberta';
        case 'closed':
            return 'Fechada';
        default:
            return 'Não mapeado';
        }
    }
}
