export default class Money {
    private readonly cents: number;

    constructor(cents: number) {
        this.cents = cents;
    }

    static fromStringBR(value: string): Money {
        return new Money(
            Money.stringBRLToCents(value)
        );
    }

    toStringBR(): string {
        return Money.centsToStringBRL(this.cents);
    }

    toNumberUS(): number {
        return this.cents / 100;
    }

    getCentsAsString(): string {
        return this.cents.toString();
    }

    private static stringBRLToCents(value: string): number {
        const formatValue = value.replaceAll('.', '').replace(',', '');
        return Number(formatValue);
    }

    public static centsToStringBRL (value: number): string {
        let cents = '';
        let rest = '';

        if (value >= 100) {
            const string = value.toString();
            cents = string.substring(string.length - 2);
            rest = string.substring(0, string.length - 2);
        } else {
            rest = '0';
            cents = value <= 9 ? '0' + value.toString() : value.toString();
        }

        const number = parseFloat(`${rest}.${cents}`);

        return number.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    }
}
