<template>
  <v-navigation-drawer temporary v-if="display.xs.value" v-model="drawer.open">
    <Content />
  </v-navigation-drawer>
  <v-navigation-drawer
      width="250"
      v-if="!display.xs.value"
      v-model="drawer.open"
      temporary
  >
    <Content />
  </v-navigation-drawer>
</template>

<script setup>
import { useDisplay } from 'vuetify';
import drawerState from '@/app/shared/infrastructure/state/drawer';
import Content from '@/app/shared/components/drawer/Content.vue';

const drawer = drawerState();
const display = useDisplay();
</script>
