<template>
  <v-container id="auth-template">
    <v-row class="mt-10 mb-10">
      <v-col class="d-flex justify-center justify-md-start">
        <a href="https://onino.com.br" >
          <v-img
              src="/images/logo.png"
              height="100"
              width="100"
          />
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-none d-md-flex">
        <v-img src="/images/accomplishment.svg" height="500"/>
      </v-col>
      <v-col class="d-flex">
        <div class="w-100 d-flex d-md-block ">
          <v-card elevation="5" class="rounded-xl pa-5 flex-grow-1" max-width="600px">
            <slot/>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
#auth-template {
  min-height: 100vh;
}

.v-messages__message {
  text-align: start !important;
}
</style>
<script setup lang="ts">
</script>