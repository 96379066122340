import { RouteRecordRaw } from 'vue-router';
import ProviderList from '@/app/provider/ui/views/ProviderList.vue';
import ProviderForm from '@/app/provider/ui/views/ProviderForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/fornecedores',
        name: 'see_providers_list',
        component: ProviderList,
    },
    {
        path: '/fornecedores/cadastrar',
        name: 'create_provider',
        component: ProviderForm,
    },
    {
        path: '/fornecedores/editar/:id',
        name: 'see_provider',
        component: ProviderForm,
        props: true,
    },
];

export default routes;
