import { API_URL_BASE } from '@/env';
import axios, { AxiosInstance } from 'axios';
import tokenRepository from '../persistence/token-repository';
import logoutService from '@/app/shared/application/services/logout';
import notificationRepository from '@/app/shared/infrastructure/persistence/notification-repository';
import ClientFactory from './ClientFactory';

export default class ApiClientFactory implements ClientFactory {
    factory(): AxiosInstance {
        const token = tokenRepository().get();
        
        const client = axios.create({
            baseURL: API_URL_BASE,
            timeout: 10000,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });

        this.addInterceptors(client);

        return client;
    }

    private addInterceptors(client: AxiosInstance) {
        client.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    logoutService().handle();
                }
        
                if (error.response?.status === 403) {
                    notificationRepository().insert({
                        id: Date.now().toString(),
                        type: 'warning',
                        message: 'Ação não permitida',
                    });
                    return Promise.reject(null);
                }
        
                if ([400, 404].includes(error.response?.status)) {
                    const message = error.response.data.message ?? null;
                    if (message && message.length !== 0) {
                        notificationRepository().insert({
                            id: Date.now().toString(),
                            type: 'warning',
                            message: message,
                        });
                    }
                    return Promise.reject(null);
                }
        
                notificationRepository().insert({
                    id: Date.now().toString(),
                    type: 'warning',
                    message: 'Desculpe, tivemos um problema interno, tente mais tarde.',
                });
                console.error('request error', error);
                return Promise.reject(null);
            }
        );
    }
}
