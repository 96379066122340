import { RouteRecordRaw } from 'vue-router';
import MyCompanyForm from '@/app/my-company/ui/views/MyCompanyForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/minha-empresa',
        name: 'see_my_company',
        component: MyCompanyForm,
    },
];

export default routes;
