export default class CnpjData {

    constructor(
        readonly name: string,
        readonly email: string,
        readonly phone: string,
        readonly zipCode: string,
        readonly street: string,
        readonly number: string,
        readonly neighborhood: string,
        readonly city: string,
        readonly state: string,
        readonly complement: string | null,
    ) {}
}
