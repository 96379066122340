import { AxiosInstance } from 'axios';
import HttpClient from '../../domain/HttpClient';
import ClientFactory from './ClientFactory';

export default class AxiosClient implements HttpClient {
    client: AxiosInstance;

    constructor(clientFactory: ClientFactory) {
        this.client = clientFactory.factory();
    }

    async get(url: string): Promise<any> {
        const response = await this.client.get(url);
        return response.data;
    }

    async post(url: string, body: any): Promise<any> {
        const response = await this.client.post(url, body);
        return response.data;
    }

    async put(url: string, body: any): Promise<any> {
        const response = await this.client.put(url, body);
        return response.data;
    }

    async delete(url: string): Promise<any> {
        const response = await this.client.delete(url);
        return response.data;
    }
}
