import BrowserRepository from '../../domain/BrowserRepository';
import DependencyInjection from '../provider/DependencyInjection';

const ROLES_KEY = 'roles';

type RoleManager = {
    insert: (roles: string[]) => void,
    has: (role: string) => boolean,
    remove: () => void,
}

export default () : RoleManager => {
    const browserRepository = DependencyInjection.inject('browserRepository') as BrowserRepository;
    
    const insert = (roles: string[]): void => {
        browserRepository.insert(ROLES_KEY, roles);
    };

    const has = (role: string): boolean => {
        if (role === 'welcome') {
            return true;
        }

        const roles = browserRepository.get(ROLES_KEY);
        if (roles) {
            return roles.includes(role) || roles.includes('admin');
        }
        
        return true;
    };

    const remove = (): void => {
        browserRepository.remove(ROLES_KEY);
    };

    return {
        insert,
        has,
        remove,
    };
};
