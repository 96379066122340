import { RouteRecordRaw } from 'vue-router';
import ServiceOrderList from '@/app/service-order/ui/views/ServiceOrderList.vue';
import ServiceOrderForm from '@/app/service-order/ui/views/ServiceOrderForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/ordens-de-servico',
        name: 'see_service_orders_list',
        component: ServiceOrderList,
    },
    {
        path: '/ordens-de-servico/cadastrar',
        name: 'create_service_order',
        component: ServiceOrderForm,
    },
    {
        path: '/ordens-de-servico/editar/:id',
        name: 'see_service_order',
        component: ServiceOrderForm,
        props: true,
    },
];

export default routes;
