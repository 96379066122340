import { RouteRecordRaw } from 'vue-router';
import CostCenterList from '@/app/cost-center/ui/views/CostCenterList.vue';
import CostCenterForm from '@/app/cost-center/ui/views/CostCenterForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/centros-de-custo',
        name: 'see_cost_centers_list',
        component: CostCenterList,
    },
    {
        path: '/centros-de-custo/cadastrar',
        name: 'create_cost_center',
        component: CostCenterForm,
    },
    {
        path: '/centros-de-custo/editar/:id',
        name: 'see_cost_center',
        component: CostCenterForm,
        props: true,
    },
];

export default routes;
