<template>
    <v-list-item
        nav
        class="py-3"
    >
      <template v-slot:prepend>
        <v-img src="/images/logo.png" height="60" width="60"></v-img>
      </template>

      <template v-slot:append>
        <v-btn
            variant="text"
            icon="mdi:mdi-chevron-left"
            @click.stop="drawer.toggle()"
        ></v-btn>

      </template>
    </v-list-item>

    <v-list nav v-model:opened="open">
      <v-list-item to="/dashboard" prepend-icon="mdi:mdi-home-city" title="Dashboard" value="Dashboard" active-color="primary" />

      <v-list-subheader >
        <p>FERRAMENTAS</p>
      </v-list-subheader>

      <v-list-group v-for="tool in tools" :value="tool.title" :key="tool.title">
        <template v-slot:activator="{ props }">
          <v-list-item
              v-bind="props"
              append-icon="mdi:mdi-chevron-down"
              :title="tool.title"
              :prepend-icon="tool.icon"
              active-color="primary"
          />
        </template>

        <v-list-item
            active-color="primary"
            style="padding-inline-start: 30px !important;"
            v-for="item in tool.items"
            :title="item.title"
            :value="item.title"
            :key="item.title"
            :to="item.link"
        >
          <template v-slot:prepend>
            <v-icon icon="mdi:mdi-circle-small" class="mr-2" />
          </template>
        </v-list-item>
      </v-list-group>

      <v-list-subheader >
        <p>CADASTROS</p>
      </v-list-subheader>

      <v-list-group v-for="register in registers" :value="register.title" :key="register.title">
        <template v-slot:activator="{ props }">
          <v-list-item
              active-color="primary"
              v-bind="props"
              append-icon="mdi:mdi-chevron-down"
              :title="register.title"
              :prepend-icon="register.icon"
          />
        </template>

        <v-list-item
            style="padding-inline-start: 30px !important;"
            v-for="item in register.items"
            :title="item.title"
            :value="item.link"
            :key="item.title"
            :to="item.link"
        >
          <template v-slot:prepend>
            <v-icon icon="mdi:mdi-circle-small" class="mr-2" />
          </template>
        </v-list-item>
      </v-list-group>
    </v-list>
</template>

<script setup>
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
import drawerState from '@/app/shared/infrastructure/state/drawer';

const drawer = drawerState();

const open = ref([]);
const display = useDisplay();

const tools = [
    {
        icon: 'mdi:mdi-calculator',
        title: 'Faturamento',
        items: [
            {
                title: 'Faturas',
                link: '/faturas',
            },/*
            {
                title: 'Notas fiscais',
                link: '/notas-fiscais',
            },*/
        ],
    },
    {
        icon: 'mdi:mdi-cash',
        title: 'Financeiro',
        items: [
            {
                title: 'Reembolsos',
                link: '/reembolsos',
            },
            {
                title: 'Contas a pagar',
                link: '/contas-a-pagar',
            },
            {
                title: 'Contas a receber',
                link: '/contas-a-receber',
            },/*
            {
                title: 'Sispag',
                link: '/sispag',
            },
            {
                title: 'Cnab',
                link: '/cnab',
            },
            {
                title: 'Ofx',
                link: '/ofx',
            },
            {
                title: 'Movimentação bancária',
                link: '/movimentacao-bancaria',
            },
            {
                title: 'Investimentos',
                link: '/investimentos',
            },
            {
                title: 'Cobranças',
                link: '/cobrancas',
            },
            {
                title: 'Conciliação financeira',
                link: '/conciliacao-financeira',
            },
            {
                title: 'Parcelas',
                link: '/parcelas',
            },
            {
                title: 'Consulta serasa',
                link: '/consulta-serasa',
            },
            {
                title: 'Divida ativa',
                link: '/divida-ativa',
            },*/
        ],
    },/*
    {
        icon: 'mdi:mdi-book-open',
        title: 'Contabilidade',
        items: [
            {
                title: 'Receitas x Despesas',
                link: '/receita-despesas',
            },
            {
                title: 'Receitas x Despesas - Analítico',
                link: '/receita-despesas-analitico',
            },
        ],
    },*/
    {
        icon: 'mdi:mdi-target-variant',
        title: 'Projetos',
        items: [
            {
                title: 'Ordem de serviço',
                link: '/ordens-de-servico',
            },/*
            {
                title: 'Relatórios',
                link: '/relatorios',
            },*/
        ],
    },
];
const registers = [
    {
        icon: 'mdi:mdi-archive-plus',
        title: 'Meus cadastros',
        items: [
            {
                title: 'Minha empresa',
                link: '/minha-empresa',
            },
            {
                title: 'Colaboradores',
                link: '/colaboradores',
            },
            {
                title: 'Clientes',
                link: '/clientes',
            },
            {
                title: 'Fornecedores',
                link: '/fornecedores',
            },
            {
                title: 'Centros de custo',
                link: '/centros-de-custo',
            },
            {
                title: 'Projetos',
                link: '/projetos',
            },
        ],
    },
];
</script>
