import Repository from '@/app/shared/infrastructure/http/Repository';

export default class CollaboratorRepository extends Repository {
    uri = '/api/collaborators';

    async allowPanelAccess(id: string) {
        return await this.client.put(`${this.uri}/${id}/change-access-panel-permission/1`, {});
    }

    async removePanelAccess(id: string) {
        return await this.client.put(`${this.uri}/${id}/change-access-panel-permission/0`, {});
    }

    async updatePermission(id: string, data: any) {
        return await this.client.post(`${this.uri}/change-refund-permissions/${id}`, data);
    }
}
