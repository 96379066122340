import { RouteRecordRaw } from 'vue-router';
import BillingList from '@/app/billing/ui/views/BillingList.vue';
import BillingForm from '@/app/billing/ui/views/BillingForm.vue';
import BillingCover from '@/app/billing/ui/views/BillingCover.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/faturas',
        name: 'see_billings_list',
        component: BillingList,
    },
    {
        path: '/faturas/cadastrar',
        name: 'create_billing',
        component: BillingForm,
    },
    {
        path: '/faturas/editar/:id',
        name: 'see_billing',
        component: BillingForm,
        props: true,
    },
    {
        path: '/faturas/capa/:id',
        name: 'see_billing_cover',
        component: BillingCover,
        props: true,
    },
];

export default routes;
