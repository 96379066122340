import Repository from '@/app/shared/infrastructure/http/Repository';

export default class BillToPayRepository extends Repository {
    uri = '/api/bills-to-pay';

    async approve(id: string) {
        return await this.client.put(`${this.uri}/${id}/change-status/approve`, {});
    }

    async disapprove(id: string) {
        return await this.client.put(`${this.uri}/${id}/change-status/disapprove`, {});
    }

    async pay(id: string) {
        return await this.client.put(`${this.uri}/${id}/change-status/pay`, {});
    }

    async moveToPending(id: string) {
        return await this.client.put(`${this.uri}/${id}/change-status/pending`, {});
    }
}
