import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('overlay', () => {
    const open = ref<boolean>(false);

    const toggle = () => {
        open.value = !open.value;
    };

    const set = (state: boolean) => {
        open.value = state;
    };

    return {
        open,
        toggle,
        set,
    };
});
