<template>
    <v-btn class="ma-3" @click="handleFileEditClick" prepend-icon="mdi:mdi-attachment-plus" color="primary">
        <p class="text-capitalize">
            {{ label }}
        </p>
    </v-btn>
    <v-file-input v-model="file" ref="fileInput" class="d-none"></v-file-input>
    <v-chip label v-if="file[0]">
        <template v-slot:append>
            <v-icon @click="handleRemoveFile" size="small" icon="fas fa-times-circle"></v-icon>
        </template>
        {{ file[0]?.name }}
    </v-chip>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from 'vue';

const file = ref([]);
const fileInput = ref(null);
const props = defineProps(['label']);
const emits = defineEmits(['uploadedFile', 'removedFile']);
const handleFileEditClick = () => {
    fileInput.value.click();
};
const handleRemoveFile = () => {
    file.value = [];
};

watch(file, (newFile) => {
    const uploadedFile = newFile[0];

    if (uploadedFile) {
        emits('uploadedFile', uploadedFile);
    } else {
        emits('removedFile');
    }
});
</script>
