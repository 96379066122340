import { defineStore } from 'pinia';
import { ref } from 'vue';

interface IBreadcrumb {
    title: string,
    disabled: boolean,
    href: string,
}

export default defineStore('breadcrumbs', () => {
    const items = ref<IBreadcrumb[]>([]);

    function createTitle(value: string): string {
        const firstLetter = value.charAt(0).toUpperCase();
        const capitalizedWord = firstLetter + value.slice(1);
        return capitalizedWord.replace('-', ' ');
    }

    const setByPath = (path: string) => {
        items.value = [];

        const array = path.split('/');

        items.value.push({
            title: path === '/' ? 'Onino' : createTitle(array[1]),
            disabled: false,
            href: path === '/' ? '/#/' : `/#/${array[1]}`,
        });

        if (array.length > 2) {
            items.value.push({
                title: createTitle(array[2]),
                disabled: true,
                href: `/#/${path}`,
            });
        }
    };

    return { items, setByPath };
});
