import { defineStore } from 'pinia';
import { inject, ref } from 'vue';
import ClientRepository from './ClientRepository';

export default defineStore('clients', () => {
    const clients = ref<any[]>([]);
    const repository = inject('clientRepository') as ClientRepository;

    async function sync() {
        const response = await repository.getAllSimple();
        clients.value = response;
    }

    async function getAllSimple() {
        if (clients.value.length === 0) {
            await sync();
        }

        return clients.value;
    }

    return {
        sync,
        getAllSimple,
    };
});
