import userRepository from '@/app/shared/infrastructure/persistence/user-repository';
import rolesRepository from '@/app/shared/infrastructure/persistence/role-repository';
import tokenRepository from '@/app/shared/infrastructure/persistence/token-repository';

type LogoutService = {
    handle: () => void,
}

export default () : LogoutService => {
    const handle = () => {
        tokenRepository().remove();
        rolesRepository().remove();
        userRepository().remove();
        window.location.reload();
    };

    return {
        handle,
    };
};
