import { defineStore } from 'pinia';
import { inject, ref } from 'vue';
import CostCenterRepository from './CostCenterRepository';

export default defineStore('cost-centers', () => {
    const costCenters = ref<any[]>([]);
    const repository = inject('costCenterRepository') as CostCenterRepository;

    async function sync() {
        const response = await repository.getAllSimple();
        costCenters.value = response;
    }

    async function getAllSimple() {
        if (costCenters.value.length === 0) {
            await sync();
        }

        return costCenters.value;
    }

    return {
        sync,
        getAllSimple,
    };
});
