import SessionRepository from '../persistence/browser/SessionRepository';

export default class DependencyInjection {

    static providers: any = {
        'browserRepository': new SessionRepository(),
    };

    static register(app: any) {
        for (const providerName in DependencyInjection.providers) {
            app.provide(providerName, DependencyInjection.providers[providerName]);
        }
    }

    static inject(provider: string): any {
        return DependencyInjection.providers[provider];
    }
}
