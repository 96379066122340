import { RouteRecordRaw } from 'vue-router';
import CollaboratorList from '@/app/collaborator/ui/views/CollaboratorList.vue';
import CollaboratorForm from '@/app/collaborator/ui/views/CollaboratorForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/colaboradores',
        name: 'see_collaborators_list',
        component: CollaboratorList,
    },
    {
        path: '/colaboradores/cadastrar',
        name: 'create_collaborator',
        component: CollaboratorForm,
    },
    {
        path: '/colaboradores/editar/:id',
        name: 'see_collaborator',
        component: CollaboratorForm,
        props: true,
    },
];

export default routes;
