import { RouteRecordRaw } from 'vue-router';
import ClientList from '@/app/client/ui/views/ClientList.vue';
import ClientForm from '@/app/client/ui/views/ClientForm.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/clientes',
        name: 'see_clients_list',
        component: ClientList,
    },
    {
        path: '/clientes/cadastrar',
        name: 'create_client',
        component: ClientForm
    },
    {
        path: '/clientes/editar/:id',
        name: 'see_client',
        component: ClientForm,
        props: true,
    },
];

export default routes;
