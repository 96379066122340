import Repository from '@/app/shared/infrastructure/http/Repository';

export default class RefundRepository extends Repository {
    uri = '/api/refunds';

    async getCategories() {
        return await this.client.get(`${this.uri}/categories`);
    }

    async approve(id: string) {
        return await this.client.put(`${this.uri}/approve/${id}`, {});
    }

    async disapprove(id: string) {
        return await this.client.put(`${this.uri}/disapprove/${id}`, {});
    }
}
