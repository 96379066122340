import { defineStore } from 'pinia';
import { inject, ref } from 'vue';
import CollaboratorRepository from './CollaboratorRepository';

export default defineStore('collaborators', () => {
    const collaborators = ref<any[]>([]);
    const repository = inject('collaboratorRepository') as CollaboratorRepository;

    async function sync() {
        const response = await repository.getAllSimple();
        collaborators.value = response;
    }

    async function getAllSimple() {
        if (collaborators.value.length === 0) {
            await sync();
        }

        return collaborators.value;
    }

    return {
        sync,
        getAllSimple,
    };
});
