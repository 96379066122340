import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import billReceivableRoutes from '@/app/bill-receivable/ui/routes';
import billToPayRoutes from '@/app/bill-to-pay/ui/routes';
import billingRoutes from '@/app/billing/ui/routes';
import clientRoutes from '@/app/client/ui/routes';
import collaboratorRoutes from '@/app/collaborator/ui/routes';
import costCenterRoutes from '@/app/cost-center/ui/routes';
import myCompanyRoutes from '@/app/my-company/ui/routes';
import projectRoutes from '@/app/project/ui/routes';
import providerRoutes from '@/app/provider/ui/routes';
import refundRoutes from '@/app/refund/ui/routes';
import serviceOrderRoutes from '@/app/service-order/ui/routes';
import sharedRoutes from '@/app/shared/ui/routes';
import useToken from '@/app/shared/infrastructure/persistence/token-repository';
import useBreadcrumbsStore from '@/app/shared/infrastructure/state/breadcrumbs';
import NotFound from '@/app/shared/ui/views/NotFound.vue';
import roleRepository from '@/app/shared/infrastructure/persistence/role-repository';
import Unauthorized from '@/app/shared/ui/views/Unauthorized.vue';
import drawerState from '@/app/shared/infrastructure/state/drawer';

const externalRoutes = [
    'login',
    'redefinir-senha',
    'criar-senha',
];

const routes: Array<RouteRecordRaw> = [
    ...billToPayRoutes,
    ...billingRoutes,
    ...clientRoutes,
    ...collaboratorRoutes,
    ...costCenterRoutes,
    ...myCompanyRoutes,
    ...projectRoutes,
    ...providerRoutes,
    ...refundRoutes,
    ...serviceOrderRoutes,
    ...sharedRoutes,
    ...billReceivableRoutes,
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
    },
    {
        path: '/nao-autorizado',
        component: Unauthorized,
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const token = useToken().get();

    if (
        !externalRoutes.includes(to.name as string)
        && !token
    ) {
        next({path: '/login', replace: true});
    } else if (
        to.path !== '/nao-autorizado'
        && !roleRepository().has(to.name as string ?? '')
    ) {
        next({path: '/nao-autorizado'});
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    const store = useBreadcrumbsStore();
    store.setByPath(to.path);
    drawerState().open = false;
});

export default router;
